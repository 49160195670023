import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import Layout from "@layouts/index"
import SEO from "@components/seo"
import pageCss from "@css/components/common/page.module.styl"
import newsCss from "@css/components/news/news.module.styl"

import Footer from '@components/Footer'


class News001 extends React.PureComponent {

    render(){
        const { data } = this.props
        const { bg01 } = data
        return(
            <div>
                <SEO title="NEWS" />
                <BackgroundImage className={pageCss.bg} fluid={bg01.childImageSharp.fluid}/>
                <main>
                    <div className={pageCss.container}>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>ライブ配信の違法アップロードに関してのお知らせ</p>
                            </div>
                            <div className={newsCss.container}>
                                <p>
                                    いつもSUGARをご利用頂きまして、誠にありがとうございます。<br/><br/>
                                    SUGARアプリにて配信されたアーティスト様のライブ配信映像を録画・撮影し、Youtube, Twitter, その他SNSに投稿されるケースを確認しております。<br/>
                                    以前より利用規約でもご案内しておりますが、配信の違法アップロード行為は、利用規約並びに法律により固く禁じられております。<br/><br/>
                                    既に動画プラットフォーム各社様に対し弊社より措置は取っておりますが、ユーザー様におきましては引き続き良識を持ってご利用頂けますよう重ねてお願い致します。<br/><br/>
                                    また、SUGAR配信に係る違法アップロード行為を目にされたお客様においては、どうぞ<a className={newsCss.link} href="/contact">弊社窓口宛</a>にご連絡を頂けるよう重ねてお願い致します。<br/><br/>
                                    引き続き、SUGARのサービスをご愛顧頂けますようどうぞよろしくお願い申し上げます。<br/><br/>
                                    SUGAR<br/><br/><br/>
                                </p>
                            </div>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>Notice Regarding Illegal Uploads of Live Streams</p>
                            </div>
                            <div className={newsCss.container}>
                                <p>
                                    Thank you very much for using SUGAR.<br/><br/>
                                    We have confirmed cases of artists recording and filming their live streams and posting them on Youtube, Twitter, and other SNS.<br/>
                                    As previously announced in our Terms of Service, illegal uploading of live streams is strictly prohibited under the Terms of Service and the law.<br/><br/>
                                    We have already taken action against video platform companies, however, we ask users to continue using the services in good faith.<br/><br/>
                                    If you observe any illegal uploads with respect to the SUGAR service, <a className={newsCss.link} href="/contact">please contact and notify us.</a><br/><br/>
                                    We thank you very much for your continued support of SUGAR.<br/><br/>
                                    SUGAR
                                </p>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

export default News001

export const query = graphql`
    query {
        bg01 : file(relativePath: { eq: "global__img__sugar-bg-03@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 842) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

